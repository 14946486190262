/* eslint-disable no-useless-escape */
import React from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import SelectInput from "../../components/form/inputs/select-input/select-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { emailReg, passwordReg, showToast } from "../../utils/utils";
import TextInput from "../../components/form/inputs/text-input/text-input";
import axiosInstance from "../../config/axios";
import { useAppContext } from "../../context/app.context";
import Button from "../../components/button/button";

export default function RegisterPage() {
  const navigate = useNavigate();
  const { setAppInfos } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const roles = [
    { label: "Un prestataire", value: "Prestataire" },
    { label: "Un cavalier", value: "Cavalier" },
    { label: "Un propriétaire", value: "Propriétaire" },
  ];
  const fromMobileApp = searchParams.get("mobile") == "yes";
  if (!fromMobileApp) {
    roles.push({ label: "Une structure", value: "Structure" });
  }
  const passwordRules = [
    "Plus de 8 caractères",
    "Au minimum une majuscule et une minuscule",
    "Doit contenir un chiffre et un caractère spécial",
  ];
  function onLogin() {
    if (fromMobileApp) {
      (window as any)?.ReactNativeWebView?.postMessage("login");
    } else {
      navigate("/login");
    }
  }

  async function onSubmit(form: any) {
    const role = form.role;
    try {
      const { data } = await axiosInstance.post("/register", form);
      setAppInfos({ token: data.token, user: data.user });
      if (role == "Prestataire")
        navigate("/register/prestataire/informations", { state: { ...form, role, lists: data.lists, fromMobileApp } });
      else if (role == "Structure")
        navigate("/register/structure/manager", { state: { ...form, role, fromMobileApp } });
      else navigate("/register/cavalier/informations", { state: { ...form, role, lists: data.lists, fromMobileApp } });
    } catch (e: any) {
      if (e?.response?.status == 409) showToast("Cette adresse email est déjà prise par un compte");
      else showToast("Une erreur est survenue");
      return;
    }
  }
  return (
    <LogoLayout
      showMobileBack={fromMobileApp}
      showLogo={true}
      params={{fromMobileApp:fromMobileApp}}
      onMobileBack={()=>(window as any )?.ReactNativeWebView?.postMessage("close") }
      renderAfterSubmit={() => (
        <div className="bottomlink" >
          <span className="currentR">Vous avez déjà un compte ?</span>
          <Button onClick={onLogin} className="btn-tertiary" title="Connectez-vous" />
        </div>
      )}
      submitTitle="S'inscrire"
      onSubmit={onSubmit}
      hideBack={true}
    >
      <h1>Bienvenue !</h1>
      <h2 className="title">{"S'inscrire"}</h2>
      <div>
        <SelectInput
          placeHolder="Un prestataire, un cavalier, une structure..."
          options={roles}
          name="role"
          label="Je suis"
          required="Il faux sélectionner votre role"
        />
        <TextInput
          name="email"
          autocomplete="off"
          type="email"
          placeHolder="Votre email"
          label="Adresse e-mail"
          validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
        />
        <TextInput
          name="password"
          autocomplete="new-password"
          showPassword
          type="password"
          placeHolder="Votre mot de passe"
          label="Mot de passe"
          helper={passwordRules}
          error={passwordRules}
          validate={(val: any) => (!passwordReg.test(val) ? "mot de passe invalide" : true)}
        />
        <TextInput
          autocomplete="new-password"
          name="confirm"
          showPassword
          type="password"
          placeHolder="Votre mot de passe"
          label="Confirmation du mot de passe"
          validate={(val: any, form: any) =>
            !val || val != form.password ? "Le mot de passe n'est pas identique à celui saisi" : true
          }
        />
      </div>
    </LogoLayout>
  );
}
