import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Structurelayout from "../../components/structureLayout/structurelayout";

export default function ContactPage() {
  return (
    <Structurelayout hideheader={true} showBack={true}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
          <h1 className="mb-4">Contact</h1>
            <div className="currentR">
              <span className="currentM">Contactez-nous</span>
              <br />
              <br />
              <span className="currentM">Hykonnect</span>
              <br />
              <span style={{display:'block',marginTop:5,marginBottom:10}}>138 rue du Bourg Belé 72000 Le Mans, France</span>
              <p>
                <span className="currentM">E-mail :</span> gladys@hykonnect.com
              </p>
              <p>
                <span className="currentM">Téléphone :</span> 06 02 35 87 93 <br />
                <br />
                <span className="currentM">
                  Informations légales
                </span>
              </p>
              <p>
                <span className="currentM">Numéro de SIRET : </span> 925 159 261 00013
              </p>
              <p>
                <span className="currentM">Registre du Commerce et des Sociétés : </span> 925 159 261 R.C.S. Le Mans
              </p>
              <p>
                <span className="currentM">Directrice de publication :</span> Gladys de Labbey
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Structurelayout>
  );
}
