import React, { useState } from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { useAppContext } from "../../../context/app.context";
import Button from "../../../components/button/button";
import { FormProvider, useForm } from "react-hook-form";
import { ibanreg, showToast } from "../../../utils/utils";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";
import axiosInstance from "../../../config/axios";
import * as _ from "lodash";
import DateTimeInput from "../../../components/form/inputs/datetime-input/date-time-input";
import { useNavigate } from "react-router-dom";
import DocumentPicker from "../../../components/documentpicker/documentpicker";
export default function AddIbanPage() {
  const { appInfos, setAppInfos } = useAppContext();
  const methods = useForm({ mode: "onBlur" });
  const [ibaninfo, setibaninfo] = useState({ ...(appInfos.user.ibanInfos || {}) });
  const navigate = useNavigate();
  const [file, setfile] = useState(null);
  const [submited, setsubmited] = useState(false);
  const disabled = _.isEqual(ibaninfo, appInfos.user.ibanInfos || {}) || file == null;
  async function onSubmit(form: any) {
    try {
      setsubmited(true);
      if (!file) return;
      const { name, iban, adress, birthday } = form;
      const formData = new FormData();
      formData.append("name", name);
      formData.append("iban", iban);
      formData.append(
        "adress",
        JSON.stringify({
          ...adress,
          city: adress?.administrative_area_level_1,
        }),
      );
      formData.append("birthday", new Date(birthday).toISOString());
      formData.append("pidentite", file);
      const { data } = await axiosInstance.post("users/bankaccount", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setAppInfos({ user: { ...appInfos.user, ...data.data } });
      navigate("/structure/profil/iban");
    } catch (e: any) {
      showToast("Une erreur est survenue");
    }
  }
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  return (
    <Structurelayout showBack header={Header()}>
      <FormProvider {...methods}>
        <div className="graycard">
          <div className="ibanform">
            <div className="row">
              <h3 style={{ marginBottom: 10 }}>Votre IBAN</h3>
              <div className="col-md-6">
                <TextInput
                  value={ibaninfo.name}
                  onChange={(val: any) => setibaninfo({ ...ibaninfo, name: val })}
                  required="Nom invalide"
                  placeHolder="Nom"
                  label="Nom figurant sur votre IBAN*"
                  name="name"
                />
              </div>
              <div className="col-md-6">
                <TextInput
                  className="ibaninput"
                  required="IBAN invalide"
                  onChange={(val: any) => setibaninfo({ ...ibaninfo, iban: val })}
                  placeHolder="IBAN"
                  label="IBAN*"
                  validate={(val: any) => (ibanreg.test(val) ? true : "IBAN invalide")}
                  value={ibaninfo.iban}
                  name="iban"
                />
              </div>
              <div className="col-md-6">
                <PlacesInput
                  onChange={(val: any) => setibaninfo({ ...ibaninfo, address: val })}
                  value={ibaninfo.adress}
                  required
                  placeHolder="Adresse"
                  label="Adresse*"
                  name="adress"
                />
              </div>
              <div className="col-md-6">
                <DateTimeInput
                  value={ibaninfo.birthday}
                  name="birthday"
                  onChange={(val: any) => setibaninfo({ ...ibaninfo, birthday: val })}
                  label="Date de naissance*"
                  max={eighteenYearsAgo}
                  required="Il faux sélectionner le  type de la structure"
                />
              </div>
              <div>
                <DocumentPicker
                  label="Ajout d’une pièce d’identité*"
                  error={submited && !file ? "Il faux ajouter une copie de votre pièce d’identité " : ""}
                  onChange={setfile}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flexEnd" style={{ flexGrow: 1, alignItems: "flex-end" }}>
          <div className="gy-3 row">
          <div style={{padding:0}} className="col">
            <Button
              className="btn-secondary cancelbtniban"
              onClick={() => navigate("/structure/profil/iban")}
              title="Annuler"
            />
          </div>
          <div className="col btnenreg" style={{ padding:0 }}>
            <Button
              disabled={disabled}
              className="btn-primary"
              onClick={methods.handleSubmit(onSubmit,()=>setsubmited(true))}
              title="Enregistrer"
            />
          </div>
          </div>
          
        </div>
      </FormProvider>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h2>{"Ajout d'un IBAN"}</h2>
    </div>
  );
}
