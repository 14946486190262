import React from "react";
import StructurelayoutLP from "../../components/structureLayoutLP/structurelayoutLP";
import { Link } from "react-router-dom";
import Button from "../../components/button/button";
import { useAppContext } from "../../context/app.context";

export default function LandingPage() {
  return (
    <StructurelayoutLP headerleft={HeaderLeft()} headerright={HeaderRight()}>
      <div className="bg-bleu ele" id="plateforme">
        <div className="container text-light px-3 px-md-0">
          <h2 className="discover-title">Découvrez la plateforme Hykonnect pour vos écuries !</h2>
          <div className="container discover-row">
            <div className="row discover-row-container">
              <div className="col disover-texts">
                <p className="subtitle text-light">Centralisation des échanges et des paiements</p>
                <p className="currentR text-light">
                  Hykonnect simplifie la gestion des équidés et des professionnels aux propriétaires, grâce à une
                  application mobile intuitive.
                </p>
              </div>
              <div className="col ">
                <img src="/images/imgLP1.svg" alt="Logo" />
              </div>
            </div>
            <div className="row discover-row-container-bottom">
              <div className="col d-none d-md-block">
                <img src="/images/imgLP2.svg" alt="Logo" />
              </div>
              <div className="col disover-texts">
                <p className="subtitle text-light">Une plateforme web optimisée pour une expérience fluide</p>
                <p className="currentR text-light">
                  Découvrez notre application web complète conçue pour répondre à tous vos besoins en matière de gestion
                  équestre.
                </p>
              </div>
              <div className="col d-block d-md-none">
                <img src="/images/imgLP2.svg" alt="Logo" />
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="bg-light pb-104" style={{ position: "relative" }} id="fonctionnalites">
        <div className="container text-primary-color px-3 px-md-0">
          <h2 className="discover-advantages">Découvrez les avantages et les fonctionnalités</h2>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <p className="currentR discover-advantages-paragraph">
                Hykonnect simplifie la communication, les échanges et les flux financiers grâce à des fonctionnalités
                ciblées d un utilisateur vers un professionnel :
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="col d-flex mb-40">
                  <div className="mr-16">
                    <img src="/images/chart.svg" alt="Logo" />
                  </div>
                  <div>
                    <p className="text-primary-color currentM" style={{ marginBottom: 4 }}>
                      Gagnez en efficacité
                    </p>
                    <p className="text-primary-color currentR">Gestion complète des données équines</p>
                  </div>
                </div>
                <div className="col d-flex mb-40">
                  <div className="mr-16">
                    <img src="/images/settings.svg" alt="Logo" />
                  </div>
                  <div>
                    <p className="text-primary-color currentM" style={{ marginBottom: 4 }}>
                      Evitez les impayés
                    </p>
                    <p className="text-primary-color currentR">Logiciel de facturation automatisé</p>
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="mr-16">
                    <img src="/images/messaging.svg" alt="Logo" />
                  </div>
                  <div>
                    <p className="text-primary-color currentM" style={{ marginBottom: 4 }}>
                      Partagez facilement les informations
                    </p>
                    <p className="text-primary-color currentR">Communication efficace entre prestataires</p>
                  </div>
                </div>
              </div>

              <div className="col er">
                <div className=" d-flex mb-40">
                  <div className="mr-16">
                    <img src="/images/calendarPrimary.svg" alt="Logo" />
                  </div>
                  <div>
                    <p className="text-primary-color currentM" style={{ marginBottom: 4 }}>
                      Découvrez les prestataires disponibles
                    </p>
                    <p className="text-primary-color currentR">Prise de rendez-vous simplifiée</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mr-16">
                    <img src="/images/card.svg" alt="Logo" />
                  </div>
                  <div>
                    <p className="text-primary-color currentM" style={{ marginBottom: 4 }}>
                      Réglez directement vos prestations
                    </p>
                    <p className="text-primary-color currentR">Service de paiement intégré</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <img src="/images/LPele3.png" alt="Logo" className="ele3" />
        <img src="/images/LPele4.png" alt="Logo" className="ele4" /> */}
      </div>
      <div className="bg-light100">
        {/* <div className="container text-primary-color pb-105">
          <h2 className="discover-tarification">Tarification</h2>
          <div className="row ">
            <div className="col">
              <div className="card whitecard ">
                <h3 className="card-header bg-light text-center">Hyko Propriétaire</h3>
                <div className="card-body">
                  <ul>
                    <li className="currentR mb-16 mt-32">Application mobile</li>
                    <li className="currentR mb-16">Facturation</li>
                    <li className="currentR mb-16">Messagerie instantanée</li>
                    <li className="currentR mb-16">Planning</li>
                    <li className="currentR mb-16">Prise de rendez-vous</li>
                    <li className="currentR mb-40">Paiement intégré</li>
                  </ul>
                </div>
                <div className="subtitle text-center mb-40 ">Gratuit</div>
              </div>
            </div>
            <div className="col">
              <div className="card whitecard">
                <h3 className="card-header bg-light text-center">Hyko Prestataire</h3>
                <div className="card-body">
                  <ul>
                    <li className="currentR mt-32 mb-16">Application mobile</li>
                    <li className="currentR mb-16">6 mois offerts</li>
                    <li className="currentR mb-16">Facturation</li>
                    <li className="currentR mb-16">Messagerie instantanée</li>
                    <li className="currentR mb-16">Planning</li>
                    <li className="currentR mb-16">Prise de rendez-vous</li>
                    <li className="currentR mb-16">Paiement intégré</li>
                    <li className="currentR mb-16">Visibilité</li>
                  </ul>
                </div>
                <div className="subtitle text-center pb-40">19.90€ par mois</div>
              </div>
            </div>
            <div className="col">
              <div className="card whitecard">
                <h3 className="card-header bg-light text-center ">Hyko Écurie</h3>
                <div className="card-body">
                  <ul>
                    <li className="currentR mt-32 mb-16">Site web</li>
                    <li className="currentR mb-16">6 mois offerts</li>
                    <li className="currentR mb-16">Facturation</li>
                    <li className="currentR mb-16">Gestion des clients</li>
                    <li className="currentR mb-16">Gestion des documents</li>
                    <li className="currentR mb-16">Tarification</li>
                    <li className="currentR mb-16">Visibilité sur l&apos;application</li>
                    <li className="currentR mb-16">Paiement intégré</li>
                  </ul>
                </div>
                <div className="subtitle text-center pb-40">29.90€ par mois</div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </StructurelayoutLP>
  );
}
function HeaderLeft() {
  const { appInfos } = useAppContext();
  return (
    <>
      <div className="header-left-container">
        <div className="row">
          <div className="header-content col-10">
            <div className="row">
              <div className="d-flex flex-row">
                <h1 className="headertitle">
                  Bienvenue sur <span className="name">Hykonnect</span>
                </h1>
              </div>
            </div>
            <h2 className="headertitle mt-16" style={{ fontWeight: 700 }}>
              Votre solution tout-en-un pour simplifier la gestion des équidés.
            </h2>
            <p className="currentR description mt-32">
              Hykonnect permet la centralisation de tous les flux et les échanges entre les différents prestataires du
              monde équin et les propriétaires de chevaux.
            </p>

            <div className="row mt-40 mb-140">
              <div className="d-flex flex-row">
                <Link title="register" to="/register">
                  <Button type="submit" className="button btn-primary text-light ph-20 " title="S’inscrire" />
                </Link>
                <div style={{ marginLeft: 40 }}>
                  <Link title="login" to="/login">
                    <Button type="submit" className="button btn-tertiary mt-16 " title="Se connecter" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="/images/LPele1.png" style={{ position: "absolute", top: 0, left: 0, zIndex: 10 }} alt="" />
      <img src="/images/LPele2.png" style={{ position: "absolute", bottom: -19, right: -120, zIndex: 99 }} alt="" />
    </>
  );
}
function HeaderRight() {
  return (
    <>
      <img className="horse-img" src="/images/horse.svg" alt="horse" />
    </>
  );
}
