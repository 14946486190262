import React, { useState } from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { useAppContext } from "../../../context/app.context";
import Button from "../../../components/button/button";
import { FormProvider, useForm } from "react-hook-form";
import { emailReg, passwordReg, showToast } from "../../../utils/utils";
import axiosInstance from "../../../config/axios";
import Modal from "../../../components/modal/modal";

export default function AccountPage() {
  const { appInfos, setAppInfos } = useAppContext();
  const user = appInfos.user;
  const [showmodal, setshowmodal]: any = useState("");
  const methods = useForm({ mode: "onBlur" });
  const [email, setemail] = useState(user.email);
  const [password, setpassword] = useState("");
  const disabled = email == user.email && !password;
  async function onConfirmModal() {
    try {
      if (showmodal == "logout") {
        setAppInfos({ token: null, user: null });
      } else {
        await axiosInstance.delete("users/account/delete");
        setAppInfos({ token: null, user: null });
      }
      setshowmodal("");
    } catch (e) {}
  }
  const passwordRules = [
    "Plus de 8 caractères",
    "Au minimum une majuscule et une minuscule",
    "Doit contenir un chiffre et un caractère spécial",
  ];
  async function onSubmit(form: any) {
    try {
      const submitData: any = { email: form.email };
      if (form.password) submitData.password = form.password;
      const { data } = await axiosInstance.post("/users/account/update", submitData);
      setAppInfos({ user: { ...appInfos.user, ...data } });
      showToast("Votre compte a été bien mis a jour", "success");
    } catch (e: any) {
      if (e?.response?.status) {
        if (e?.response?.status == 409) showToast("Cette adresse email est déjà prise par un compte");
        else showToast("Une erreur est survenue");
      }
    }
  }

  return (
    <Structurelayout header={Header()}>
      <FormProvider {...methods}>
        <div className="row gy-3">
          <div className="col-sm-12 col-md-6">
            <div className="graycard">
              <h3 style={{marginBottom:10}}>Votre compte</h3>
              <TextInput
                name="email"
                autocomplete="off"
                type="email"
                value={email}
                onChange={setemail}
                label="E-mail"
                validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="graycard">
              <h3 style={{marginBottom:10}}>Changer de mot de passe</h3>
              <TextInput
                name="password"
                autocomplete="new-password"
                showPassword
                type="password"
                onChange={setpassword}
                value={password}
                placeHolder="Votre mot de passe"
                label="Mot de passe"
                errorIcon="/images/error.svg"
                helper={passwordRules}
                error={passwordRules}
                validate={(val: any) => (!val ? true : !passwordReg.test(val) ? "mot de passe invalide" : true)}
              />
              <div >
                <TextInput
                  autocomplete="new-password"
                  name="confirm"
                  showPassword
                  type="password"
                  placeHolder="Votre mot de passe"
                  label="Confirmation du mot de passe"
                  validate={(val: any, form: any) =>
                    !form.password
                      ? true
                      : !val || val != form.password
                      ? "Le mot de passe n'est pas identique à celui saisi"
                      : true
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 flexEnd">
          <div>
            <Button
              className="btn-primary graydisabled"
              onClick={methods.handleSubmit(onSubmit)}
              title="Enregistrer"
              disabled={disabled}
            />
          </div>
        </div>
        <Modal
          onClose={() => setshowmodal("")}
          description={
            showmodal == "logout" ? (
              <>
                <span>Êtes-vous sûr de vouloir vous déconnecter ?</span>
              </>
            ) : showmodal == "delete" ? (
              <>
                <span>Êtes-vous sûr de vouloir supprimer votre compte ?</span>
                <div style={{ marginTop: 7, marginBottom: 6 }}>Attention, toutes les données seront perdues</div>
              </>
            ) : null
          }
          title={showmodal == "logout" ? "Déconnexion" : showmodal == "delete" ? "Supprimer mon compte" : ""}
          onConfirm={onConfirmModal}
          show={showmodal != ""}
        />
        <div className="col mt-5">
          <Button
            onClick={() => setshowmodal("logout")}
            icon="/images/logout.svg"
            className="btn-tertiary mt-3"
            title="Déconnexion"
          />
          <div style={{ height: 1, backgroundColor: "#E6E7E8", maxWidth: 300, marginTop: 20 }} />
          <Button
            onClick={() => setshowmodal("delete")}
            icon="/images/delete.svg"
            className="btn-tertiary mt-3"
            title="Supprimer le compte"
          />
        </div>
      </FormProvider>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mon profil</h1>
    </div>
  );
}
