import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Structurelayout from "../../../../../components/structureLayout/structurelayout";
import Button from "../../../../../components/button/button";
import DateTimeInput from "../../../../../components/form/inputs/datetime-input/date-time-input";
import TextInput from "../../../../../components/form/inputs/text-input/text-input";
import FileInput from "../../../../../components/form/inputs/file-input/file-input";
import SelectInput from "../../../../../components/form/inputs/select-input/select-input";
import "./AddDocumentPage.css";
import axiosInstance from "../../../../../config/axios";
import { useAppContext } from "../../../../../context/app.context";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import DocumentPicker from "../../../../../components/documentpicker/documentpicker";
import AutoCompleteInput from "../../../../../components/form/inputs/autocomplete/autocomplete-input";
import { showToast } from "../../../../../utils/utils";

export default function AddDocumentPage() {
  const { appInfos, setAppInfos } = useAppContext();
  const documentTypes = [
    {
      label: "Contrat",
      value: "Contrat",
    },
    {
      label: "Facture",
      value: "Facture",
    },
    {
      label: "Devis",
      value: "Devis",
    },
    {
      label: "Compte-rendu",
      value: "Compte-rendu",
    },
    {
      label: "Autre",
      value: "Autre",
    },
  ];
  const methods = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const [file, setfile]: any = useState(null);
  const [submited, setsubmited] = useState(false);
  const onSubmit = async (data: any) => {
    setsubmited(true);
    if (!file) return;

    try {
      const formData = new FormData();
      if (data.client) formData.append("client", data.client._id);
      formData.append("title", data.titre);
      formData.append("date", data.date);
      formData.append("type", data.type);
      formData.append("file", file);
      await axiosInstance.post("/documents", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
      });
      navigate("/structure/gestion/documents");
    } catch (error) {
      showToast("Une erreur est survenue");
    }
  };
  return (
    <Structurelayout showBack header={Header()}>
      <FormProvider {...methods}>
        <div className="graycard">
          <div className="ibanform">
            <div className="row">
              <h3 style={{ marginBottom: 10 }}>Informations</h3>
              <div className="col-md-6">
                <DateTimeInput name="date" label="Date*" required="Date invalide" />
              </div>
              <div className="col-md-6">
                <TextInput placeHolder="Titre" name="titre" label="Titre*" required="Titre invalide" />
              </div>
              <div className="col-md-6">
                <SelectInput
                  name="type"
                  options={documentTypes}
                  label="Type de Document"
                  required="Il faux sélectionner le  type de la structure"
                />
              </div>
              <div className="col-md-6">
                <AutoCompleteInput
                  name="client"
                  errorMsg="Veuillez sélectionner un client valide."
                  url="structure/clients/search/client"
                  label="Client"
                  placeHolder="Client"
                />
              </div>
              <div>
                <DocumentPicker
                  label="Ajout de mon document*"
                  error={submited && !file ? "Il faux sélectionner votre document" : ""}
                  onChange={setfile}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flexEnd" style={{ flexGrow: 1, alignItems: "flex-end" }}>
          <div>
            <Button
              className="btn-secondary cancelbtniban"
              onClick={() => navigate("/structure/profil/iban")}
              title="Annuler"
            />
          </div>
          <div style={{ marginLeft: 15 }}>
            <Button
              className="btn-primary"
              onClick={methods.handleSubmit(onSubmit, () => setsubmited(true))}
              title="Enregistrer"
            />
          </div>
        </div>
      </FormProvider>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h2>{"Ajouter un Document"}</h2>
    </div>
  );
}
