import "./check-input.css";
import React, { useEffect, useState } from "react";
export default function CheckBoxInput(props: CheckboxInputType) {
  const [value, setValue] = useState(props.value);
  function onChange(event: any) {
    setValue(event.target.checked);
    if (props.onChange) props.onChange(event.target.checked);
  }
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <div>
      <div style={props.containerStyle} className={`mb3 input-container form-check ${props.containerClass || ""}`}>
        {props.label !== undefined && (
          <label
            style={props.labelStyle}
            htmlFor={props.inputId}
            className={`form-check-label ${props.labelClass || ""}`}
          >
            {props.label}
          </label>
        )}
        <input
          className={`form-check-input ${props.inputClass || ""}`}
          type={"checkbox"}
          checked={value ? true : false}
          style={props.inputStyle}
          required={props.required || false}
          name={props.name}
          form={props.form}
          id={props.inputId}
          readOnly={props.readonly || false}
          disabled={props.disabled || false}
          onChange={onChange}
        />
        <div className="invalid-feedback">{props.errorMsg || "Invalid value"}</div>
      </div>
    </div>
  );
}
export interface CheckboxInputType {
  label?: string; //done
  labelClass?: string; //done
  labelStyle?: React.CSSProperties; //done
  value?: boolean | 0 | 1; //done
  inputClass?: string; //dne
  inputStyle?: React.CSSProperties; //done
  inputId?: string; //done
  name?: string;
  form?: string;
  disabled?: boolean; //done
  readonly?: boolean; //done
  errorMsg?: string;
  required?: boolean;
  onChange?: Function;
  containerClass?: string; //done
  containerStyle?: React.CSSProperties; //done
}
