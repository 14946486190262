import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Structurelayout from "../../components/structureLayout/structurelayout";

export default function CGUPage() {
  return (
    <Structurelayout hideheader={true} showBack={true}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} className="text-center">
            <h1 className="mb-4">Politique de Confidentialité</h1>
            <p className="text-muted mb-4 currentR">
              Politique de Confidentialité de <strong>Hykonnect.com</strong>
            </p>
            <p className="text-start currentR">
              La présente politique de confidentialité décrit comment{" "}
              <a href="Hykonnect.com" target="_blank">
                Hykonnect.com
              </a>{" "}
              (ci-après &quot;le Site&quot;, &quot;nous&quot;, &quot;notre&quot;) collecte, utilise, protège et partage
              les informations personnelles de ses utilisateurs. Hykonnect.com est une plateforme numérique permettant
              de faciliter la gestion des flux entre les professionnels du secteur équin (tels que les moniteurs
              d&apos;équitation, vétérinaires, maréchaux-ferrants, etc.) et les cavaliers/propriétaires de chevaux
              (ci-après &quot;les Utilisateurs&quot;). En accédant et en utilisant notre Site, vous acceptez les termes
              de cette politique de confidentialité.
            </p>

            <h2 className="mt-5 text-start">1. Informations que nous collectons</h2>
            <h3 className="mt-4 text-start">1.1 Informations personnelles des utilisateurs</h3>
            <p className="text-start currentR">
              Lorsque vous vous inscrivez en tant que cavalier/propriétaire de cheval ou en tant que professionnel du
              secteur équin, nous collectons les informations suivantes :
            </p>
            <ul className="text-start currentR">
              <li className="currentR">Prénom et nom</li>
              <li className="currentR">Adresse e-mail</li>
              <li className="currentR">Numéro de téléphone</li>
              <li className="currentR">Adresse physique</li>
              <li className="currentR">Date de naissance</li>
              <li className="currentR">
                Informations relatives aux chevaux : nom, âge, sexe, race, conditions médicales (si applicables)
              </li>
              <li className="currentR">Niveau d&apos;équitation et galop estimé (pour les cavaliers)</li>
              <li className="currentR">Historique des rendez-vous et des prestations (pour les cavaliers et les professionnels)</li>
              <li className="currentR">Données de paiement (si applicable, pour le règlement des prestations)</li>
            </ul>

            <h3 className="mt-4 text-start">1.2 Informations professionnelles</h3>
            <p className="text-start currentR">
              Si vous êtes un professionnel (moniteur, vétérinaire, maréchal-ferrant, etc.), nous collectons les
              informations suivantes :
            </p>
            <ul className="text-start currentR">
              <li className="currentR">Nom et prénom</li>
              <li className="currentR">Adresse e-mail</li>
              <li className="currentR">Numéro de téléphone professionnel</li>
              <li className="currentR">Spécialité et qualifications</li>
              <li className="currentR">Adresse professionnelle (pour la géolocalisation des services)</li>
              <li className="currentR">Tarifs et disponibilité (afin de faciliter la gestion des rendez-vous)</li>
              <li className="currentR">Historique des rendez-vous et des prestations réalisées</li>
            </ul>

            <h3 className="mt-4 text-start">1.3 Informations de navigation</h3>
            <p className="text-start currentR">
              Nous collectons des informations liées à l&apos;utilisation de notre Site, telles que :
            </p>
            <ul className="text-start currentR">
              <li className="currentR">Adresse IP</li>
              <li className="currentR">Type de navigateur et appareil</li>
              <li className="currentR">Pages visitées et interactions avec la plateforme</li>
              <li className="currentR">Données des cookies (voir section &quot;Cookies&quot; ci-dessous)</li>
            </ul>

            <h2 className="mt-5 text-start">2. Utilisation des informations</h2>
            <p className="text-start currentR">
              Nous utilisons les informations que nous collectons pour les finalités suivantes :
            </p>
            <ul className="text-start currentR">
              <li className="currentR">
                <strong>Gestion des utilisateurs :</strong> Créer et gérer votre compte, faciliter les connexions avec
                les professionnels du secteur équin.
              </li>
              <li className="currentR">
                <strong>Rendez-vous et gestion des prestations :</strong> Organiser, gérer et suivre les rendez-vous
                entre les cavaliers/propriétaires de chevaux et les professionnels.
              </li>
              <li className="currentR">
                <strong>Amélioration de notre plateforme :</strong> Analyser l&apos;utilisation du Site pour améliorer
                son contenu, sa performance et ses fonctionnalités.
              </li>
              <li className="currentR">
                <strong>Communication :</strong> Vous envoyer des notifications relatives à votre compte, à vos
                rendez-vous, des offres promotionnelles (si vous avez consenti à les recevoir), ou des informations
                importantes.
              </li>
              <li className="currentR">
                <strong>Sécurité :</strong> Protéger votre compte et la sécurité de la plateforme.
              </li>
            </ul>

            <p className="mt-5 text-start currentR">
              Si vous avez des questions, n&apos;hésitez pas à nous contacter par email à{" "}
              <a href="mailto:gladys@hykonnect.com">gladys@hykonnect.com</a> ou par courrier à l&apos;adresse suivante :{" "}
              <strong>138 rue du Bourg Belé, 72000 Le Mans</strong>.
            </p>
          </Col>
        </Row>
      </Container>
    </Structurelayout>
  );
}
