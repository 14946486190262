import React, { useEffect, useState } from "react";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import List from "../../../components/lists/list";
import axiosInstance from "../../../config/axios";
import { formatPrice, getFactureTagClass, getFactureTagTitle, showToast } from "../../../utils/utils";
import Button from "../../../components/button/button";
import moment from "moment";
import { useAppContext } from "../../../context/app.context";
import { SubscriptionItem } from "../../register/registerSubscription.page";

export default function SubscriptionPage() {
  const [data, setdata]: any = useState({});
  const [loading, setloading]: any = useState([]);
  const [plans, setplans] = useState([]);
  const [selected, setselected] = useState("monthly");
  const { appInfos, setAppInfos } = useAppContext();
  const [isCanceled, setisCanceled]: any = useState(false);
  const [cancelDate, setCancelDate]: any = useState("");
  const monthlyPlan: any = plans?.find((p: any) => p.period == "monthly");
  const yearlyPlan: any = plans?.find((p: any) => p.period == "yearly");
  const columns = [
    {
      name: "type",
      cellClass: "currentM",
      flex: 2,
      fnValue: (val: any) => (val?.lines?.data[0]?.plan?.interval == "year" ? "Facture annuelle" : "Facture mensuelle"),
    },
    {
      name: "type",
      cellClass: "currentR tcenter",
      flex: 2,
      fnValue: (val: any) => moment(val?.created)?.format("DD/MM/YYYY"),
    },
    {
      name: "amount",
      flex: 1.5,
      cellClass: "currentR tcenter",
      fnValue: (val: any) => formatPrice(val.amount_paid / 100) + " €",
    },
    {
      name: "status",
      justifyContent: "center",
      cellClassFn: () => getFactureTagClass({ status: "Réglée" }),
      fnValue: () => getFactureTagTitle({ status: "Réglée" }),
    },
    {
      name: "download",
      flex: 0.5,
      cell: (elt: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <a href={elt.invoice_pdf} style={{ cursor: "pointer" }}>
              <img src={"/images/download.svg"} alt="modifier" />
            </a>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const { data } = await axiosInstance.get("users/account/payments");
        data.payments = data.payments?.filter((elt: any) => elt.amount_paid > 0 && elt.subscription);
        setdata(data);
        setAppInfos({ user: { ...appInfos.user, ...data.user } });
        setplans(data.plans);
        if (data.stripesub?.cancel_at) {
          setisCanceled(true);
          setCancelDate(new Date(data.stripesub?.cancel_at * 1000));
        }
      } catch (e) {
        showToast("Une erreur est survenue");
      }
      setloading(false);
    })();
  }, []);
  async function updateSubscription() {
    try {
      const { data } = await axiosInstance.post("/users/account/updatesubscription", {
        successUrl:
          window.location.origin +
          (appInfos.user.role == "Prestataire" ? "/prestataire" : "/structure") +
          "/profil/subscription",
        cancelUrl:
          window.location.origin +
          (appInfos.user.role == "Prestataire" ? "/prestataire" : "/structure") +
          "/profil/subscription",
        period: selected,
      });
      window.location.href = data.session.url;
      return;
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  return (
    <Structurelayout loading={loading} header={Header()}>
      {plans.length ? (
        <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
          <div className="graycard">
            <div className="row">
              <div className="col">
                <div className="row">
                  <h3>Votre abonnement</h3>
                  {!data.subscription ? (
                    <div style={{ marginTop: 20 }}>
                      <div style={{ maxWidth: 500, backgroundColor: "white", borderRadius: 14 }}>
                        <SubscriptionItem
                          free={monthlyPlan?.free_days}
                          price={monthlyPlan?.price}
                          title="Mensuel"
                          unit="par mois"
                          noFree={true}
                          onClick={() => setselected("monthly")}
                          selected={selected == "monthly"}
                        />
                      </div>
                      <div style={{ backgroundColor: "white", maxWidth: 500, borderRadius: 14 }}>
                        <SubscriptionItem
                          noFree={true}
                          selected={selected == "yearly"}
                          free={yearlyPlan?.free_days}
                          price={yearlyPlan?.price}
                          onClick={() => setselected("yearly")}
                          title="Annuel"
                          unit="par an"
                        />
                      </div>
                    </div>
                  ) : null}
                  {data.subscription ? (
                    <span style={{ marginTop: 15 }} className="subtitle">
                      Abonnement{" "}
                      {data?.subscription?.plan?.period == "yearly"
                        ? "Annuel"
                        : data?.subscription?.plan?.period == "monthly"
                          ? "Mensuel"
                          : ""}
                    </span>
                  ) : null}
                  {data.subscription ? (
                    <span style={{ marginTop: 10 }} className="currentR">
                      {formatPrice(data?.subscription?.plan?.price)}€ /{" "}
                      {data?.subscription?.plan?.period == "yearly" ? " an" : " mois"}
                    </span>
                  ) : null}
                </div>
              </div>
              {/* <div className="col-auto">
                fkùfkdkfd
              </div> */}
            </div>
          </div>
          <div
            style={{
              marginTop: 40,
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <List data={data.payments} columns={columns} title="Factures" />
          </div>
          <div className="mt-4 flexEnd">
            <div>
              <Button onClick={() => updateSubscription()} className="btn-primary graydisabled" title="Modifier" />
            </div>
          </div>
        </div>
      ) : null}
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h1 className="headertitle">Mon profil</h1>
    </div>
  );
}
