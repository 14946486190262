import React from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import { Link, Navigate } from "react-router-dom";
import Button from "../../components/button/button";
export default function PasswordSuccessPage() {
  const redirect = window.history.state.idx == 0;
  if (redirect) return <Navigate to={"/password/reset"} replace />;
  return (
    <LogoLayout hideBack={true}>
      <div className="successContainer">
        <img className="successimg" src={"/images/checked.svg"} alt="" />
        <h1 className="successTitle">Votre mot de passe</h1>
        <span className="subtitle">a bien été enregistré !</span>
        <div className="successLink">
          <Link to="/login" replace>
            <Button title="Connection" />
          </Link>
        </div>
      </div>
    </LogoLayout>
  );
}
