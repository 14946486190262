import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Structurelayout from "../../../../components/structureLayout/structurelayout";
import Button from "../../../../components/button/button";
import DateTimeInput from "../../../../components/form/inputs/datetime-input/date-time-input";
import TextInput from "../../../../components/form/inputs/text-input/text-input";
import FileInput from "../../../../components/form/inputs/file-input/file-input";
import SelectInput from "../../../../components/form/inputs/select-input/select-input";
import axiosInstance from "../../../../config/axios";
import { useAppContext } from "../../../../context/app.context";
import { useLocation, useNavigate } from "react-router-dom";
import * as _ from "lodash";
import DocumentPicker from "../../../../components/documentpicker/documentpicker";
import AutoCompleteInput from "../../../../components/form/inputs/autocomplete/autocomplete-input";
import { emailReg, formatNumber, formatPhone, formatPrice, phonereg, showToast } from "../../../../utils/utils";
import CheckBoxInput from "../../../../components/form/inputs/check-input/check-input";
import PlacesInput from "../../../../components/form/inputs/places-input/places-inputs";
import { Modal as Mmodal } from "react-responsive-modal";
export default function AddFacturePage() {
  const location = useLocation();
  const params = location.state || {};
  const methods = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const [showmodal, setshowmodal] = useState(false);
  const [loading, setloading] = useState(false);
  const [tarifs, settarifs] = useState([]);
  const [file, setfile]: any = useState(null);
  const [submited, setsubmited] = useState(false);
  const onSubmit = async (data: any) => {
    try {
      if (!items.length) {
        return showToast("Veuillez ajouter au moins un tarif.");
      }
      if (params?._id) {
        data.client = params._id;
      }
      if (isHyconnectClient) {
        data.recepteur = {
          email: data.email,
          phone: data.phone,
          first_name: data.first_name,
          last_name: data.last_name,
          address: data.address,
        };
        data.client = undefined;
        data.innerReceiver = false;
      } else {
        if (data.client && !data.client._id) data.client = params;
        data.recepteur = data.client;
      }
      data.prestations = items;

      data.paymentMethod = data?.paymentMethod?.value || data?.paymentMethod;

      if (!data.paymentMethod) data.paymentMethod = undefined;
      await axiosInstance.post("/factures", data);
      setshowmodal(true);
      methods.reset();
    } catch (error) {
      showToast("Une erreur est survenue");
    }
  };
  const categoryTypes = [
    {
      label: "Pension",
      value: "Pension",
    },
    {
      label: "Soins",
      value: "Soins",
    },
    {
      label: "Pharmacie",
      value: "Pharmacie",
    },
    {
      label: "Matériel",
      value: "Matériel",
    },
    {
      label: "Vestimentaire",
      value: "Vestimentaire",
    },
    {
      label: "Coaching",
      value: "Coaching",
    },
    {
      label: "Concours",
      value: "Concours",
    },
    {
      label: "Déplacements",
      value: "Déplacements",
    },
    {
      label: "Assurance",
      value: "Assurance",
    },
    {
      label: "Alimentation",
      value: "Alimentation",
    },
    {
      label: "Prestations externes",
      value: "Prestations externes",
    },
    {
      label: "Gains",
      value: "Gains",
    },
    {
      label: "Consultations",
      value: "Consultations",
    },
    {
      label: "Autres",
      value: "Autres",
    },
  ];
  useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const response = await axiosInstance.get("/tarifs");
        settarifs(response.data);
      } catch (error) {
      }
      setloading(false);
    })();
  }, []);
  const [status, setstatus]: any = useState("");
  const [isHyconnectClient, setIsHyconnectClient] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [items, setitems] = useState([]);
  return (
    <Structurelayout loading={loading} showBack header={Header()}>
      <FormProvider {...methods}>
        <div className="graycard">
          <div className="ibanform">
            <div className="row">
              <h3 style={{ marginBottom: 10 }}>Vos informations</h3>
              <div className="col-md-4">
                <SelectInput
                  required="Il faux sélectionner le  type de la facture"
                  label="Type"
                  name="type"
                  placeHolder="Sélectionner dans la liste"
                  options={[
                    { label: "Bénéfice", value: "Bénéfice" },
                    { label: "Dépense", value: "Dépense" },
                  ]}
                />
              </div>
              <div className="col-md-4">
                <TextInput placeHolder="Titre" name="title" label="Titre" required="Titre invalide" />
              </div>
              <div className="col-md-4">
                <SelectInput
                  name="category"
                  placeHolder="Sélectionner dans la liste"
                  options={categoryTypes}
                  label="Catégorie"
                  required="Il faux sélectionner la catégorie de la facture"
                />
              </div>
              <div className="col-md-4">
                <DateTimeInput
                  name="prestationDate"
                  required="Date de prestation invalide"
                  label="Date de prestation"
                />
              </div>
              <div className="col-md-4">
                <DateTimeInput name="emissionDate" required="Date d’émission invalide" label="Date d’émission" />
              </div>
              <div className="col-md-4">
                <DateTimeInput name="echeanceDate" required="Date d’échéance invalide" label="Date d’échéance" />
              </div>
              <div className="col-md-4">
                <SelectInput
                  name="status"
                  placeHolder="Sélectionner dans la liste"
                  options={["Réglée", "À régler"].map((elt) => ({ label: elt, value: elt }))}
                  label="Statut"
                  value={status}
                  onChange={(val: any) => setstatus(val.value)}
                  required="Il faux sélectionner le statut de la facture"
                />
              </div>
              {(status.value || status) == "Réglée" && (
                <div className="col-md-4">
                  <SelectInput
                    name="paymentMethod"
                    placeHolder="Sélectionner dans la liste"
                    options={["Virement bancaire", "Chèques/Espèces"].map((elt) => ({ label: elt, value: elt }))}
                    label="Méthode de paiement"
                    required="Il faux sélectionner une méthode de paiement"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row gy-3" style={{ marginTop: 10 }}>
          <div className="col">
            <TarifsLists onItems={(itms: any) => setitems(itms)} tarifs={tarifs} />
          </div>
          <div className="col">
            <div className="graycard">
              <div className="add-client-form">
                <div className=" searchaddclient">
                  <h3 style={{ marginBottom: 10 }}>Assignation</h3>
                  <AutoCompleteInput
                    name="client"
                    url="/structure/clients/search/client"
                    label="Destinataire"
                    value={params?._id ? params?.first_name + " " + params.last_name : undefined}
                    className="searchinp"
                    required={!isHyconnectClient && !params?._id ? "Destinataire invalide" : false}
                    autocomplete="off"
                    empty={isHyconnectClient }
                    placeHolder="Rechercher un destinataire"
                    onSelectItem={(userId) => {
                      setSelectedUserId(userId);
                    }}
                    disabled={params?._id || isHyconnectClient}
                  />
                  {!params._id && (
                    <CheckBoxInput
                      label="Le destinataire n’est pas référencé"
                      disabled={params._id}
                      onChange={(value: any) => {
                        setIsHyconnectClient((old) => !old);
                      }}
                    />
                  )}
                  {isHyconnectClient && (
                    <div
                      className="assignclient"
                      style={{
                        borderRadius: 30,
                        padding: 24,
                        borderStyle: "dotted",
                        borderColor: "#B3C2CA",
                        borderWidth: 1,
                        marginTop: 15,
                        width: "100%",
                      }}
                    >
                      <div className="row">
                        <div className="col">
                          <TextInput required="Nom invalide" name="last_name" label="Nom" placeHolder="Nom" />
                        </div>
                        <div className="col">
                          <TextInput required="Prénom invalide" name="first_name" label="Prénom" placeHolder="Prénom" />
                        </div>
                        <div className="col-12">
                          <PlacesInput
                            className="inp-100"
                            name="address"
                            required="Adresse invalide"
                            placeHolder="Adresse"
                            label="Adresse"
                          />
                        </div>
                        <div className="col">
                          <TextInput
                            validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
                            name="email"
                            className="inp100"
                            label="E-mail"
                            placeHolder="adresse@mail.fr"
                          />
                        </div>
                        <div className="col">
                          <TextInput
                            type="tel"
                            validate={(val: any) =>
                              !phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true
                            }
                            label="Téléphone"
                            name="phone"
                            format={formatPhone}
                            maxLength={14}
                            placeHolder="00 00 00 00 00"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 flexEnd" style={{ flexGrow: 1, alignItems: "flex-end" }}>
          <div>
            <Button
              className="btn-secondary cancelbtniban"
              onClick={() => navigate("/structure/profil/iban")}
              title="Annuler"
            />
          </div>
          <div style={{ marginLeft: 15 }}>
            <Button
              className="btn-primary"
              onClick={methods.handleSubmit(onSubmit, () => setsubmited(true))}
              title="Enregistrer"
            />
          </div>
        </div>
      </FormProvider>
      <Mmodal
        closeIcon={() => null}
        classNames={{
          modal: "appmodal confirmfacture",
        }}
        open={showmodal}
        onClose={() => {
          setshowmodal(false);
        }}
        center
      >
        <button className="btnclose" onClick={() => setshowmodal(false)}>
          <img width={28} src="/images/close.svg" alt="" />
        </button>
        <div style={{ marginTop: 50 }} className="modalcontent">
          <img alt="" src="/images/bigcheck.svg" />
          <div style={{ marginTop: 20, marginBottom: 10 }} className="modaltext">
            <h3 style={{ textAlign: "center" }}>Votre facture a bien été envoyée</h3>
            <div style={{ textAlign: "center" }} className="currentR modaldesc">
              Vous pouvez la consulter depuis votre liste de factures.
            </div>
          </div>
          <Button
            className="btn-primary mb-3 mt-3"
            onClick={() => {
              setshowmodal(false);
              if (params._id) navigate("/structure/clients/" + params._id);
              else navigate("/structure/gestion/factures");
            }}
            title={"Retour"}
          />
        </div>
      </Mmodal>
    </Structurelayout>
  );
}
function Header() {
  return (
    <div>
      <h2>{"Ajout d’une facture"}</h2>
    </div>
  );
}

function TarifsLists(props: any) {
  const [selectedItem, setselectedItem]: any = useState({});
  const [tva, settva]: any = useState(0);
  const [montantHT, setmontantHT]: any = useState(0);
  const [ttc, setttc]: any = useState(0);
  const [items, setitems] = useState([]);
  useEffect(() => {
    const htValue = parseFloat(montantHT || "0");
    const tvaValue = parseFloat(tva?.value != undefined ? tva.value : tva);
    setttc(!tva ? htValue : htValue * (1 + tvaValue / 100));
  }, [montantHT, tva?.value, tva]);
  useEffect(() => {
    const item = props.tarifs.find((elt: any) => elt._id == selectedItem.value);
    setmontantHT((item?.ht?.toString()?.replace(",",".")));
    settva(item?.tva);
  }, [selectedItem?.value]);

  return (
    <div>
      <div className="graycard">
        <h3 style={{ marginBottom: 10 }}>Votre montant</h3>
        <SelectInput
          inputClass="inp100"
          name="presta"
          value={selectedItem}
          onChange={setselectedItem}
          placeHolder="Sélectionner dans la liste"
          options={props.tarifs.map((elt: any) => ({ label: elt.title, value: elt._id }))}
          label="Prestation"
          required="prestatation invalide "
        />
        <div className="popup-form-inputs">
          <div>
            <TextInput
              label="Montant HT"
              autocomplete="off"
              name="montant_ht"
              rightIcon={
                <span style={{ color: "#B3C2CA" }} className="currentR">
                  €
                </span>
              }
              format={formatNumber}
              maxLength={25}
              required="Montant HT invalide"
              placeHolder="Montant €"
              value={montantHT + ""}
              onChange={(val:any)=>setmontantHT(+((val+"")?.replace(",",".")))}
            />
          </div>
          <div>
            <SelectInput
              label="TVA"
              name="tva"
              value={tva + ""}
              onChange={(val: any) => settva(val.value)}
              placeHolder="%"
              required="TVA (%) invalide"
              options={[
                {
                  label: "0 %",
                  value: "0",
                },
                {
                  label: "2,1 %",
                  value: "2.1",
                },
                {
                  label: "2,5 %",
                  value: "2.5",
                },
                {
                  label: "3 %",
                  value: "3",
                },
                {
                  label: "3,7 %",
                  value: "3.7",
                },
                {
                  label: "5 %",
                  value: "5",
                },
                {
                  label: "5,5 %",
                  value: "5.5",
                },
                {
                  label: "6 %",
                  value: "6",
                },
                {
                  label: "7,7 %",
                  value: "7.7",
                },
                {
                  label: "8 %",
                  value: "8",
                },
                {
                  label: "10 %",
                  value: "10",
                },
                {
                  label: "12 %",
                  value: "12",
                },
                {
                  label: "14 %",
                  value: "14",
                },
                {
                  label: "17 %",
                  value: "17",
                },
                {
                  label: "20 %",
                  value: "20",
                },
                {
                  label: "21 %",
                  value: "21",
                },
              ]}
            />
          </div>
        </div>
        <hr />
        <div className="popup-form-ttc d-flex justify-content-between">
          <h4 style={{ fontSize: 18 }} className="currentM">
            Prix TTC
          </h4>
          {!isNaN(ttc) && montantHT && tva && <h4 className="currentM">{formatPrice(ttc)} €</h4>}
        </div>
        <div style={{ marginTop: 12 }}>
          <Button
            disabled={tva == undefined || !montantHT}
            onClick={() => {
              const itms: any = [...items];
              itms.push({ title: selectedItem.label, ht: montantHT, tva: tva, ttc: ttc });
              props.onItems(itms);
              setitems(itms);
              settva(undefined);
              setmontantHT(undefined);
              setselectedItem("");
            }}
            icon="/images/addpl.svg"
            title="Ajouter le tarif"
            className="btn btn-tertiary"
          />
        </div>
      </div>
      {items.map((elt: any, ind) => {
        return (
          <div
            key={ind}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#F4F4F7",
              padding: 16,
              borderRadius: 20,
              marginBottom: 10,
              marginTop: ind == 0 ? 20 : 0,
            }}
          >
            <span className="currentM" style={{ fontSize: 16 }}>
              {elt.title}
            </span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="currentXS" style={{ fontSize: 16 }}>
                {formatPrice(elt.ttc) + " €"}
              </span>
              <div
                onClick={() => {
                  const itms: any = [...items];
                  itms.splice(ind, 1);
                  props.onItems(itms);
                  setitems(itms);
                }}
              >
                <img style={{ marginLeft: 7, cursor: "pointer" }} alt="" src="/images/deleteitem.svg" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function Assignation() {
  return null;
}
