import React from "react";
import LogoLayout from "../../../components/logolayout/logolayout";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import DateTimeInput from "../../../components/form/inputs/datetime-input/date-time-input";
import { emailReg, formatPhone, phonereg, showToast } from "../../../utils/utils";
import axiosInstance from "../../../config/axios";

export default function RegisterCavalierTuteurPage() {
  const location = useLocation();
  const params = location.state;
  const navigate = useNavigate();
  async function onSubmit(form: any) {
    form = { ...params, tuteur: form };
    try {
      await axiosInstance.post("users/register/complete", form);
      navigate("/register/success", { state:  form  });
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  const redirect = window.history.state.idx == 0;
  if (redirect) return <Navigate to={"/register"} replace />;
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  return (
    <LogoLayout  params={params} onSubmit={onSubmit} progress={70}>
      <h1>Information complémentaire</h1>
      <p className="subtitle">{params.role}</p>
      <h2 className="title">Mon tuteur</h2>
      <div>
        <TextInput required="Nom invalide" name="last_name" placeHolder="Nom" label="Nom" />
        <TextInput
          required="Prénom invalide"
          name="first_name"
          placeHolder="Prénom"
          label="Prénom"
        />
        <DateTimeInput
          max={eighteenYearsAgo}
          name="birth_date"
          required="Date de naissance invalide"
          label="Date de naissance"
        />
        <TextInput
          validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
          name="email"
          placeHolder="E-mail"
          label="Adresse e-mail"
        />
        <TextInput
          validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
          name="phone"
          format={formatPhone}
          maxLength={14}
          placeHolder="06.00.00.00.00"
          label="Téléphone"
        />
      </div>
    </LogoLayout>
  );
}
