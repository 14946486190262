import React, { useState } from "react";
import Label from "../form/label/label";
import Button from "../button/button";
import { chooseFromFileSystem } from "../../utils/files.utils";

export default function DocumentPicker({ label, onChange, defaultName, btntitle, pattern, error }: any) {
  const [file, setfile]: any = useState(null);
  return (
    <div className="" style={{ marginTop: 6 }}>
      <Label label={label} />
      <div
        onClick={async () => {
          const f: any = await chooseFromFileSystem({ patternFile: pattern || ["image/*", ".pdf"], multiple: false });
          setfile(f[0]);
          if (onChange) onChange(f[0]);
        }}
        className="docpicker"
      >
        <div style={{ pointerEvents: "none", display: "flex", justifyContent: "center" }}>
          <img style={{ marginRight: 10 }} src="/images/editfile.svg" alt="" />
          <Button
            className="btn-tertiary"
            title={btntitle || (!file ? "Ajouter un document" : "Changer de document")}
          />
        </div>
      </div>
      {(file || defaultName) && (
        <div style={{ display: "flex", marginTop: 10, alignItems: "center" }}>
          <img alt="" src="/images/filepick.svg" />
          <span className="currentXS" style={{ fontSize: 14, marginLeft: 10, marginTop: 2 }}>
            {file?.name || defaultName}
          </span>
        </div>
      )}
      {error ? (
        <div className={`form-error-msg`}>
          <img
            style={{ marginInlineEnd: 5, marginTop: -3, marginRight: 5, width: 14, height: 14 }}
            src="/images/error.svg"
            alt=""
          />
          {error}
        </div>
      ) : null}
    </div>
  );
}
