import React from "react";
import LogoLayout from "../../../components/logolayout/logolayout";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { emailReg, formatPhone, phonereg } from "../../../utils/utils";
import TextInput from "../../../components/form/inputs/text-input/text-input";

export default function RegisterManagerPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.state;
  const redirect = window.history.state.idx == 0;
  function onSubmit(form: any) {
    form = { ...params, manager: form };
    navigate("/register/structure/informations", { state: form });
  }
  if (redirect) return <Navigate to={"/register"} replace />;
  return (
    <LogoLayout onSubmit={onSubmit} progress={30}>
      <h1>Inscription</h1>
      <p className="subtitle">{params.role}</p>
      <h2 className="title">Le gérant</h2>
      <div>
        <TextInput  required="Nom invalide" name="last_name" placeHolder="Nom" label="Nom" />
        <TextInput
          required="Prénom invalide"
          name="first_name"
          placeHolder="Prénom"
          label="Prénom"
        />
        <TextInput
          validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
          name="email"
          placeHolder="Adresse e-mail"
          label="Adresse e-mail"
        />
        <TextInput
          validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
          name="phone"
          format={formatPhone}
          maxLength={14}
          placeHolder="06.00.00.00.00"
          label="Téléphone"
        />
      </div>
    </LogoLayout>
  );
}
