import React from "react";
import Table from "../../../../components/table/table";
import Structurelayout from "../../../../components/structureLayout/structurelayout";
import { GestionHeader } from "../factures/listfactures.page";
import moment from "moment";

export default function ListDocumentPage() {
  const documentTypes = [
    {
      label: "Contrat",
      value: "Contrat",
    },
    {
      label: "Facture",
      value: "Facture",
    },
    {
      label: "Devis",
      value: "Devis",
    },
    {
      label: "Compte-rendu",
      value: "Compte-rendu",
    },
    {
      label: "Autre",
      value: "Autre",
    },
  ];
  const columns = [
    { accessorKey: "title", header: "Document", flex: 0, enableSorting: true },
    {
      accessorKey: "type",
      filterVariant: "multi-select",
      filterSelectOptions: documentTypes,
      header: "Type",
      enableSorting: true,
    },
    {
      accessorKey: "date",
      header: "Date d’ajout",
      enableSorting: true,
      Cell: ({ cell }: any) => moment(cell.getValue()).format("DD/MM/YYYY"),
    },
    {
      accessorKey: "client",
      accessorFn: (originalRow: any) =>
        (originalRow?.client?.first_name || "") + " " + (originalRow?.client?.last_name || ""),

      header: "Client",
      enableSorting: true,
    },
  ];
  //p.row.original._id

  return (
    <Structurelayout contentStyle={{ paddingTop: 12, paddingBottom: 12 }} header={GestionHeader()}>
      <Table
        buttonTitle="Ajouter un document"
        renderRowAction={(p: any) => (
          <a
            target="_blank"
            rel="noreferrer"
            href={process.env.REACT_APP_SERVER_URL + p.row.original.path}
            style={{ display: "flex" }}
          >
            <span style={{ marginLeft: 20 }} className="action">
              <img src={"/images/download.svg"} alt="download" />
            </span>
          </a>
        )}
        filtertitle={"catégorie"}
        filtername={"type"}
        filterOptions={documentTypes}
        columns={columns}
        showFilter={true}
          emptytext="Aucun document disponible"
        showSearch={false}
        dataUrl={"structure/documents"}
        title={"Gestion Factures"}
        showExport={true}
      />
    </Structurelayout>
  );
}
