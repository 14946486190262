/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Structurelayout from "../../../components/structureLayout/structurelayout";
import ClientHeader from "../../../components/client/ClientHeader";
import TextInput from "../../../components/form/inputs/text-input/text-input";
import SelectInput from "../../../components/form/inputs/select-input/select-input";
import Button from "../../../components/button/button";
import axiosInstance from "../../../config/axios";
import { emailReg, formatPhone, phonereg, showToast } from "../../../utils/utils";
import Page404 from "../../404/404.page";
import moment from "moment";
import DateTimeInput from "../../../components/form/inputs/datetime-input/date-time-input";
import PlacesInput from "../../../components/form/inputs/places-input/places-inputs";

// Define the structure for client data
interface Client {
  phone: string;
  tuteur?: any;
  birth_date: string;
  email: string;
  galop?: string;
  address: string;
  level?: string;
  role: string;
  profilePicture?: string;
  first_name: string;
  last_name: string;
  _id:string;
}

const EditClient: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [clientData, setClientData] = useState<Client | null>(null);
  const [loading, setLoading] = useState(false);
  const [is404, setis404] = useState(false);
  const [fileUSER, setfileUSER]: any = useState(null);
  const methods = useForm<Client>();
  const [isUnder18, setIsUnder18] = useState(false);

  // Fetch client data
  useEffect(() => {
    const fetchClientData = async () => {
      setLoading(true);
      try {
        // Fetch client data
        const clientResponse = await axiosInstance.get(`/structure/clients/${id}`);

        let tutorData = null;
        if (clientResponse.data.tuteur) {
          tutorData = clientResponse.data.tuteur;
        } else if (clientResponse.data.tuteurId) {
          const tuteurResponse = await axiosInstance.get(`/structure/tuteurs/${clientResponse.data.tuteurId}`);
          tutorData = tuteurResponse.data;
        }

        setClientData(clientResponse.data || null);
        // Check if under 18 when client data is loaded
        if (clientResponse.data?.birth_date) {
          setIsUnder18(moment().diff(moment(clientResponse.data.birth_date), "years") < 18);
        }
      } catch (error: any) {
        if (error?.response?.status == 422 || error?.response?.status == 404) {
          setis404(true);
        } else {
          showToast("Une erreur est survenue");
        }
      }
      setLoading(false);
    };
    fetchClientData();
  }, [id, methods]);
  const navigate = useNavigate();
  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("role", data.role);
    formData.append("email", data.email);
    formData.append("phone", data.telephone);
    formData.append("level", data.niveau);
    formData.append("galop", data.galop);
    formData.append("birth_date", moment(data.dateDeNaissance).toISOString());
    formData.append("address", JSON.stringify(data.adress));

    if (isUnder18) {
      formData.append(
        "tuteur",
        JSON.stringify({
          first_name: data.nomTuteur,
          last_name: data.prenomTuteur,
          email: data.emailTuteur,
          birth_date: data.birthTuteur,
          phone: data.telephoneTuteur,
        }),
      );
    }

    if (fileUSER) {
      formData.append("photo", fileUSER); // Append file
    }

    try {
      const response = await axiosInstance.post("/structure/clients/"+clientData?._id, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate(-1);
    } catch (error: any) {
      showToast("Une erreur est survenue");
    }
  };
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  if (is404) return <Page404 />;
  return (
    <Structurelayout
      loading={loading}
      showBack={true}
      header={
        clientData && (
          <ClientHeader
            profilePicture={
              process.env.REACT_APP_SERVER_URL + "/" + (clientData as any)?.photo || "/images/default.svg"
            }
            onEditImage={(file: any) => {
              setfileUSER(file);
            }}
            name={`${clientData.first_name} ${clientData.last_name}`}
            userRole={clientData.role}
            hideButtons={true}
            onUpdate={methods.handleSubmit(onSubmit)}
          />
        )
      }
    >
      <FormProvider {...methods}>
        <>
          <div style={{ marginTop: 30 }} className="graycard ">
            <h3 style={{ marginBottom: 10 }}>Informations du Client</h3>
            <div className="row">
              <div className="col-md-4">
                <TextInput
                  required="Nom invalide"
                  autocomplete="off"
                  value={clientData?.last_name}
                  type="text"
                  name="last_name"
                  label="Nom"
                  placeHolder="Nom"
                />
              </div>
              <div className="col-md-4">
                <TextInput
                  required="Prénom invalide"
                  autocomplete="off"
                  value={clientData?.first_name}
                  type="text"
                  name="first_name"
                  label="Prénom"
                  placeHolder="Prénom"
                />
              </div>
              <div className="col-md-4">
                <SelectInput
                  label="Type de client"
                  value={clientData?.role}
                  name="role"
                  placeHolder="Type de client"
                  required="Il faux sélectionner le type de client"
                  options={[
                    { value: "Cavalier", label: "Cavalier" },
                    { value: "Propriétaire", label: "Propriétaire" },
                  ]}
                />
              </div>
              <div className="col-md-4">
                <TextInput
                  autocomplete="off"
                  value={clientData?.phone}
                  type="tel"
                  validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
                  format={formatPhone}
                  maxLength={14}
                  placeHolder="00 00 00 00 00"
                  name="telephone"
                  label="Téléphone"
                 
                />
              </div>
              <div className="col-md-4">
                <TextInput
                  autocomplete="off"
                  type="text"
                  value={clientData?.email}
                  validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
                  name="email"
                  label="E-mail"
                  placeHolder="adresse@mail.fr"
                />
              </div>
              <div className="col-md-4">
                <PlacesInput
                  required="Adresse invalide"
                  value={clientData?.address}
                  autocomplete="off"
                  type="text"
                  name="adress"
                  label="Adresse"
                  placeHolder="Adresse"
                />
              </div>
              <div className="col-md-4">
                <DateTimeInput
                  type="date"
                  value={new Date(clientData?.birth_date as any)}
                  required="Date de naissance invalide"
                  name="dateDeNaissance"
                  label="Date de naissance"
                  placeholder="JJ/MM/AAAA"
                  onChange={(val: any) => {
                    setIsUnder18(moment().diff(moment(val), "years") < 18);
                  }}
                />
              </div>
              <div className="col-md-4">
                <SelectInput
                  label="Galop obtenu"
                  value={clientData?.galop}
                  name="galop"
                  placeHolder="Galop obtenu"
                  options={[
                    { value: "Aucun", label: "Aucun" },
                    { value: "Galop 1", label: "Galop 1" },
                    { value: "Galop 2", label: "Galop 2" },
                    { value: "Galop 3", label: "Galop 3" },
                    { value: "Galop 4", label: "Galop 4" },
                    { value: "Galop 5", label: "Galop 5" },
                    { value: "Galop 6", label: "Galop 6" },
                    { value: "Galop 7", label: "Galop 7" },
                    { value: "Galop 7+", label: "Galop 7+" },
                  ]}
                />
              </div>
              <div className="col-md-4">
                <SelectInput
                  label="Niveau"
                  value={clientData?.level}
                  name="niveau"
                  placeHolder="Niveau"
                  options={[
                    { value: "Débutant", label: "Débutant" },
                    { value: "Intermédiaire", label: "Intermédiaire" },
                    { value: "Loisir", label: "Loisir" },
                    { value: "Confirmé", label: "Confirmé" },
                    { value: "Compétitions club/poney", label: "Compétitions club/poney" },
                    { value: "Compétitions amateur", label: "Compétitions amateur" },
                    { value: "Compétitions pro", label: "Compétitions pro" },
                  ]}
                />
              </div>
            </div>
          </div>

          {isUnder18 && (
            <div className="graycard" style={{ marginTop: 30 }}>
              <h3 style={{ marginBottom: 10 }}>Tuteur</h3>
              <div className="row">
                <div className="col-md-4">
                  <TextInput
                    autocomplete="off"
                    required="Nom invalide"
                    value={clientData?.tuteur?.last_name}
                    type="text"
                    name="nomTuteur"
                    label="Nom"
                    placeHolder="Nom"
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    autocomplete="off"
                    required="Prénom"
                    value={clientData?.tuteur?.first_name}
                    type="text"
                    name="prenomTuteur"
                    label="Prénom"
                    placeHolder="Prénom"
                  />
                </div>
                <div className="col-md-4">
                  <DateTimeInput
                    name="birthTuteur"
                    value={clientData?.tuteur?.birth_date}
                    required="Date de naissance invalide"
                    max={eighteenYearsAgo}
                    label="Date de naissance"
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    type="text"
                    validate={(val: any) => (!phonereg.test(val?.replace(/\D/g, "")) ? "Téléphone invalide" : true)}
                    format={formatPhone}
                    maxLength={14}
                    value={clientData?.tuteur?.phone}
                    name="telephoneTuteur"
                    autocomplete="off"
                    label="Téléphone"
                    placeHolder="00 00 00 00 00"
                  />
                </div>
                <div className="col-md-4">
                  <TextInput
                    autocomplete="off"
                    validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
                    type="text"
                    name="emailTuteur"
                    value={clientData?.tuteur?.email}
                    label="Email"
                    placeHolder="email@mail.com"
                  />
                </div>
              </div>
            </div>
          )}
        </>
        <div className="mt-4 flexEnd" style={{ flexGrow: 1, alignItems: "flex-end" }}>
          <div style={{ marginLeft: 15 }}>
            <Button onClick={methods.handleSubmit(onSubmit)} className="btn-primary" title="Enregistrer" />
          </div>
        </div>
      </FormProvider>
    </Structurelayout>
  );
};

export default EditClient;
