import React from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import Button from "../../components/button/button";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { formatCode, showToast } from "../../utils/utils";
import axiosInstance from "../../config/axios";
export default function PasswordCodePage() {
  const location = useLocation();
  const params = location.state;
  const navigate = useNavigate();
  async function onSubmit(form: any) {
    const code = form.code.replace(/\D/g, "");
    const email = params.email;
    try {
      await axiosInstance.post("users/password/verify", { email, code });
      navigate("/password/confirm", { state: { ...params, code } });
    } catch (e: any) {
      if (e.response?.status == 422) showToast("Code invalide");
      else showToast("Une erreur est survenue");
    }
  }
  async function onResendCode() {
    try {
      await axiosInstance.post("users/resend/emailcode", params);
    } catch (e) {
      showToast("Une erreur est survenue");
    }
  }
  const redirect = window.history.state.idx == 0;
  if (redirect) return <Navigate to={"/password/reset"} replace />;
  return (
    <LogoLayout
      onSubmit={onSubmit}
      renderBeforeSubmit={() => (
        <div style={{ marginBottom: 20 }}>
          <span className="currentR">Vous n’avez pas reçu de code ?</span>
          <Button onClick={onResendCode} title="Renvoyer un code" className="btn-tertiary m-0" />
        </div>
      )}
    >
      <h2 className="title">{"Mot de passe oublié"}</h2>
      <p className="currentR">Veuillez renseigner le code envoyer sur votre boite mail.</p>
      <div>
        <TextInput
          name="code"
          maxLength={7}
          format={formatCode}
          autocomplete="off"
          validate={(val: any) => (val?.replace(/\D/g, "").length < 4 ? "Code invalide" : true)}
          type="tel"
          placeHolder="X X X X"
          label="Code"
        />
      </div>
    </LogoLayout>
  );
}
