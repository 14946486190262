import React from "react";
import "./ClientInfo.css";

interface ClientInfoProps {
  telephone: string;
  birthDate: string;
  istuteur?: boolean;
  email: string;
  address?: string; // Make address optional
  title: string;
  first_name?: string;
  last_name?: string;
  galop?: string; // Only for client
  level?: string; // Only for client
}

const ClientInfo: React.FC<ClientInfoProps> = ({
  telephone,
  birthDate,
  email,
  address,
  title,
  first_name,
  last_name,
  galop,
  istuteur,
  level,
}) => (
  <div className="client-info-container">
    <h2>{title}</h2>
    {!istuteur ? (
      <div className="client-info-row">
        <div className="client-info-column">
          <span className="currentXS">Telephone</span>

          <span style={{ display: "block" }} className="currentM">
            {telephone}
          </span>
          <div style={{ marginTop: 10 }}>
            <span className="currentXS">Date de naissance</span>
            <span style={{ display: "block" }} className="currentM">
              {birthDate}
            </span>
          </div>
        </div>
        <div className="client-info-column">
          <span className="currentXS">Email</span>
          <span style={{ display: "block" }} className="currentM">
            {email}
          </span>

          {galop && (
            <div style={{ marginTop: 10 }}>
              <span className="currentXS">Galop Obtenu</span>
              <span style={{ display: "block" }} className="currentM">
                {galop}
              </span>
            </div>
          )}
        </div>
        <div className="client-info-column">
          <span className="currentXS">Adresse</span>
          <span style={{ display: "block" }} className="currentM">
            {address}
          </span>

          {level && (
            <div style={{ marginTop: 10 }}>
              <span className="currentXS">Niveau</span>
              <span style={{ display: "block" }} className="currentM">
                {level}
              </span>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className="client-info-row">
        <div className="client-info-column">
          <span className="currentXS">Nom</span>

          <span style={{ display: "block" }} className="currentM">
            {first_name}
          </span>
          <div style={{ marginTop: 10 }}>
            <span className="currentXS">Téléphone</span>

            <span style={{ display: "block" }} className="currentM">
              {telephone}
            </span>
          </div>
        </div>
        <div className="client-info-column">
          <span className="currentXS">Prénom</span>
          <span style={{ display: "block" }} className="currentM">
            {last_name}
          </span>
          <div style={{ marginTop: 10 }}>
            <span className="currentXS">E-mail</span>

            <span style={{ display: "block" }} className="currentM">
              {email}
            </span>
          </div>
        </div>
        <div className="client-info-column">
          <span className="currentXS">Date de naissance</span>
          <span style={{ display: "block" }} className="currentM">
            {birthDate}
          </span>
        </div>
      </div>
    )}
  </div>
);

export default ClientInfo;
