import React from "react";

export default function Progress(props: { progress: number }) {
  return (
    <div
      style={{ height: 4, backgroundColor: "rgba(252, 241, 239, 0.5)" ,marginBottom:20,marginTop:5}}
      className="progress"
      role="progressbar"
      aria-label="Basic example"
      aria-valuenow={props.progress as any}
      aria-valuemin={"0" as any}
      aria-valuemax={"100" as any}
    >
      <div
        className="progress-bar"
        style={{ width: props.progress + "%", backgroundColor: "rgba(227, 116, 97, 1)" }}
      ></div>
    </div>
  );
}
