import React, { useState } from "react";
import "./structurelayout.css";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../../context/app.context";
export default function Structurelayout(props: any) {
  const router = useLocation();
  function linkStatus(link: any) {
    return router.pathname.indexOf(link) != -1 ? "-active" : "";
  }
  const { appInfos } = useAppContext();
  const role = appInfos?.user?.role?.toLowerCase();
  const status = appInfos?.user?.status?.toLowerCase();
  const canGoBack = window?.history?.state?.idx !== 0;
  return (
    <div className="structurelayout">
      <img src="/images/ele1.png" alt="" className="backimg" />
      <div className="header">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <Link className="navbar-brand" to={role? ("/" + role):"/"}>
            <a
              className="toggle"
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              role="button"
              aria-controls="offcanvasExample"
            >
              <img className="toggle" src="/images/menu.svg" alt="" />
            </a>
            <img width={30} height={30} src="/images/Logo.svg" alt="" />
          </Link>
          {!props.hideheader && (
            <div className="navbar" id="navbarText">
              <ul className="nav ">
                {role == "structure" && status == "active" && (
                  <>
                    <li className="nav-item  me-5 ">
                      <Link className={`link${linkStatus("/structure/gestion")}`} to="/structure/gestion/factures">
                        <img
                          className="linkimg"
                          src={`/images/gestion${linkStatus("/structure/gestion")}.svg`}
                          alt=""
                        />
                        Gestion
                      </Link>
                    </li>
                    <li className="nav-item  me-5">
                      <Link className={`link${linkStatus("/structure/clients")}`} to="/structure/clients">
                        <img
                          className="linkimg"
                          src={`/images/clients${linkStatus("/structure/clients")}.svg`}
                          alt=""
                        />
                        Clients
                      </Link>
                    </li>
                    <li className="nav-item  me-5">
                      <Link className={`link${linkStatus("/structure/tarifs")}`} to="/structure/tarifs">
                        <img className="linkimg" src={`/images/tarifs${linkStatus("/structure/tarifs")}.svg`} alt="" />
                        Tarifs
                      </Link>
                    </li>
                  </>
                )}
                <li className="nav-item">
                  <Link className={`link${linkStatus(`/${role}/profil`)}`} to={`/${role}/profil/account`}>
                    <img className="linkimg" src={`/images/profil${linkStatus(`/${role}/profil`)}.svg`} alt="" />
                    <div style={{ maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis", display: "inline" }}>
                      {role == "structure"
                        ? appInfos.user?.structure?.name
                        : appInfos.user?.first_name + " " + appInfos.user?.last_name}
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </nav>
      </div>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          zIndex: 3,
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="headercontent">
          {props.showBack && canGoBack && (
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 15, alignItems: "center" }}>
              <Link to={-1 as any}>
                <img src="/images/backdark.svg" alt="" />
                <span
                  style={{
                    marginLeft: 5,
                    color: "white",
                    textDecoration: "underline",
                    fontFamily: "Rubik-Medium",
                    fontSize: 18,
                  }}
                >
                  Retour
                </span>
              </Link>
            </div>
          )}
          {props.header}
          {router.pathname.indexOf("/profil") != -1 ? (
            <div className="profilheader">
              <Link className={`link1${linkStatus("/profil/account")} me-3`} to={`/${role}/profil/account`}>
                Compte
              </Link>
              {role == "structure" && (
                <>
                  <Link className={`link1${linkStatus("/profil/structure")} me-3`} to="/structure/profil/structure">
                    Structure
                  </Link>
                  <Link className={`link1${linkStatus("/profil/manager")} me-3`} to="/structure/profil/manager">
                    Gérant
                  </Link>
                </>
              )}
              <Link className={`link1${linkStatus("/profil/subscription")} me-3`} to={`/${role}/profil/subscription`}>
                Abonnement
              </Link>
              <Link className={`link1${linkStatus("/profil/iban")} me-3`} to={`/${role}/profil/iban`}>
                IBAN
              </Link>
            </div>
          ) : null}
        </div>
        <div className="content" style={{ position: "relative", ...props.contentStyle }}>
          <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
            {props.loading ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  zIndex: 4,
                  alignItems: "center",
                }}
              >
                <div style={{ color: "#e37461", width: 40, height: 40 }} className="spinner-border" role="status"></div>
              </div>
            ) : null}
            {props.children}
          </div>
        </div>
      </div>
      <div className="footer" style={{ overflow: "hidden" }}>
        <Link
          className={`link${linkStatus("/politique-de-confidentialite")}   me-5`}
          to="/politique-de-confidentialite"
        >
          Politique de confidentialité
        </Link>
        <Link className={`link${linkStatus("/cgu")}  me-5`} to="/cgu">
          CGV/CGU
        </Link>
        <Link className={`link${linkStatus("/mentions")}  me-5`} to="/mentions">
          Mentions légales
        </Link>
        <Link className={`link${linkStatus("/contact")}  me-5`} to="/contact">
          Contact
        </Link>
      </div>
      <Sidebar />
    </div>
  );
}

function Sidebar() {
  const { appInfos } = useAppContext();
  const role = appInfos?.user?.role?.toLowerCase();
  const status = appInfos?.user?.status?.toLowerCase();
  const router = useLocation();
  const [showprofil, setshowprofil] = useState(false);
  function linkStatus(link: any) {
    return router.pathname.indexOf(link) != -1 ? "-active" : "";
  }
  return (
    <div className="offcanvas" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-body">
        <div className="sidebar">
          <img width={24} height={24} src="/images/Logo.svg" alt="" />

          <div className="sidebartop">
            {role == "structure" && status == "active" && (
              <>
                <Link className={`link${linkStatus("/structure/gestion")}`} to="/structure/gestion/factures">
                  <img className="linkimg" src={`/images/gestion${linkStatus("/structure/gestion")}.svg`} alt="" />
                  Gestion
                </Link>
                <Link className={`link${linkStatus("/structure/clients")} mt-40`} to="/structure/clients">
                  <img className="linkimg " src={`/images/clients${linkStatus("/structure/clients")}.svg`} alt="" />
                  Clients
                </Link>
                <Link className={`link${linkStatus("/structure/tarifs")} mt-40`} to="/structure/tarifs">
                  <img className="linkimg" src={`/images/tarifs${linkStatus("/structure/tarifs")}.svg`} alt="" />
                  Tarifs
                </Link>
              </>
            )}
            <Link
              className={`link${linkStatus("/structure/profil")} mt-40`}
              to="/structure/profil/account"
              onClick={(e) => {
                e.preventDefault();
                setshowprofil(true);
              }}
            >
              <img className="linkimg" src={`/images/profil${linkStatus("/structure/profil")}.svg`} alt="" />
              Profil
            </Link>
            {showprofil || router.pathname.indexOf("/profil") != -1 ? (
              <>
                <Link className={`link1${linkStatus("/profil/account")} mt-20`} to="/structure/profil/account">
                  Compte
                </Link>
                {role == "structure" && (
                  <>
                    <Link className={`link1${linkStatus("/profil/structure")} `} to="/structure/profil/structure">
                      Structure
                    </Link>
                    <Link className={`link1${linkStatus("/profil/manager")} `} to="/structure/profil/manager">
                      Gérant
                    </Link>
                  </>
                )}
                <Link className={`link1${linkStatus("/profil/subscription")}`} to={`/${role}/profil/subscription`}>
                  Abonnement
                </Link>
                <Link className={`link1${linkStatus("/profil/iban")}`} to={`/${role}/profil/iban`}>
                  IBAN
                </Link>
              </>
            ) : null}
          </div>
          <div className="sidebarbottom">
            <Link className={`link${linkStatus("/politique-de-confidentialite")}`} to="/politique-de-confidentialite">
              Politique de confidentialité
            </Link>
            <Link className={`link${linkStatus("/cgu")} mt-20`} to="/cgu">
              CGV/CGU
            </Link>
            <Link className={`link${linkStatus("/mentions")} mt-20`} to="/mentions">
              Mentions légales
            </Link>
            <Link className={`link${linkStatus("/contact")} mt-20`} to="/contact">
             Contact
           </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
