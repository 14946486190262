import React from "react";
import LogoLayout from "../../components/logolayout/logolayout";
import TextInput from "../../components/form/inputs/text-input/text-input";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axios";
import { showToast } from "../../utils/utils";
import { useAppContext } from "../../context/app.context";
import DateTimeInput from "../../components/form/inputs/datetime-input/date-time-input";

export default function LoginPage() {
  const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const { setAppInfos } = useAppContext();
  const navigate = useNavigate();
  async function onSubmit(form: any) {
    try {
      const { data } = await axiosInstance.post("login", form);
      if (data.user.role != "Structure" && data.user.role != "Prestataire")
        if (data.user.role != "ADMIN") {
          return showToast(
            "En tant que cavalier ou propriétaire, vous ne pouvez pas accéder à la plateforme web. Téléchargez l’application Hykonnect depuis Google Play Store ou l’App Store pour vous connecter et bénéficier de toutes les fonctionnalités disponibles.",
            "error",
            10000,
          );
        } else {
          return showToast("Les informations de connexion sont incorrectes");
        }
      else if (data.user.role == "Prestataire") {
        showToast(
          "Pour accéder à toutes les fonctionnalités de Hykonnect, téléchargez notre application depuis Google Play Store ou l’App Store et connectez-vous avec vos identifiants.",
          "warning",
          false,
        );
      }
      setAppInfos({ token: data.token, user: data.user });
      setTimeout(() => {
        navigate(
          data.user.role == "Structure"
            ? data.user.status == "Active"
              ? "/structure"
              : "/structure/profil"
            : "/prestataire/profil",
        );
      }, 200);
    } catch (e: any) {
      if (e?.response?.status == 422) showToast("Les informations de connexion sont incorrectes");
      else showToast("Une erreur est survenue");
    }
  }
  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  return (
    <LogoLayout
      renderAfterSubmit={() => (
        <div className="bottomlink">
          <span className="currentR">Pas encore de compte ?</span>
          <Link className="btn-tertiary" title="login" to="/register">
            <span>S’inscrire</span>
          </Link>
        </div>
      )}
      submitTitle="Se connecter"
      onSubmit={onSubmit}
      hideBack={true}
    >
      <h1>Bienvenue !</h1>
      <h2 className="title">Se connecter</h2>
      <div>
        {/* <DateTimeInput
          max={eighteenYearsAgo}
          name="birth_date"
          required="Date de naissance invalide"
          label="Date de naissance"
        />
        <DateTimeInput label="oooo" /> */}
        <TextInput
          validate={(val: any) => (!emailReg.test(val) ? "Adresse e-mail invalide" : true)}
          name="email"
          autocomplete="off"
          type="email"
          placeHolder="Votre email"
          label="Adresse e-mail"
        />
        <TextInput
          className="flex-row"
          autocomplete="off"
          showPassword
          name="password"
          type="password"
          required="Mot de passe invalide"
          placeHolder="Votre mot de passe"
          label="Mot de passe"
        />
        <div className="loginlink">
          <span className="currentR">Mot de passe oublié ?</span>
          <Link className="btn-tertiary" title="login" to="/password/reset">
            <span>Cliquez ici</span>
          </Link>
        </div>
      </div>
    </LogoLayout>
  );
}
